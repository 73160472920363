import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function HandCoin({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "var(--primary)",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M300.787 350.799C294.599 350.799 289.582 345.783 289.582 339.594C289.582 333.405 294.598 328.389 300.787 328.389C309.34 328.389 316.301 321.428 316.301 312.875C316.301 304.323 309.34 297.363 300.787 297.363L233.198 297.481C233.192 297.481 233.184 297.481 233.177 297.481C231.378 297.481 229.604 297.047 228.008 296.217L169.684 265.885L107.214 265.885C101.025 265.885 96.0088 260.869 96.0088 254.68C96.0088 248.491 101.025 243.475 107.214 243.475L172.424 243.475C174.224 243.475 175.997 243.908 177.593 244.738L235.907 275.065L300.767 274.953C321.698 274.953 338.71 291.964 338.71 312.874C338.71 333.785 321.698 350.799 300.787 350.799Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M277.948 428.11H239.681C238.302 428.11 236.935 427.857 235.647 427.359L134.603 388.374H107.991C101.802 388.374 96.7861 383.358 96.7861 377.169C96.7861 370.98 101.802 365.964 107.991 365.964H136.69C138.068 365.964 139.436 366.218 140.724 366.715L241.767 405.7L275.557 405.7C283.501 402.272 298.615 396.789 318.759 389.479C365.723 372.438 436.696 346.686 461.28 329.965C468.014 325.385 468.92 316.783 465.898 311.44C460.821 302.457 446.089 302.209 427.458 310.801C427.153 310.942 426.84 311.069 426.522 311.182L317.039 350.152C315.834 350.58 314.563 350.8 313.282 350.8L208.998 350.799C202.809 350.799 197.793 345.783 197.793 339.594C197.793 333.405 202.809 328.389 208.998 328.389L311.347 328.389L418.544 290.233C454.353 273.903 476.553 284.755 485.406 300.41C494.623 316.71 489.563 337.832 473.884 348.497C446.98 366.798 377.29 392.084 326.404 410.546C306.239 417.864 288.822 424.183 282.706 427.052C281.217 427.749 279.592 428.11 277.948 428.11Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.213 416.126H21.2051C15.016 416.126 10 411.109 10 404.92V227.383C10 221.195 15.0162 216.178 21.2051 216.178H107.213C113.402 216.178 118.418 221.194 118.418 227.383V404.922C118.418 411.11 113.403 416.126 107.213 416.126ZM32.4104 393.717H96.0082V238.588H32.4104V393.717Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M288.165 216.33C248.862 216.33 217 184.468 217 145.165C217 105.862 248.862 74 288.165 74C327.468 74 359.33 105.862 359.33 145.165C359.284 184.449 327.449 216.284 288.165 216.33ZM288.165 83.5187C254.138 83.5187 226.553 111.103 226.553 145.13C226.553 179.158 254.138 206.742 288.165 206.742C322.192 206.742 349.777 179.158 349.777 145.13C349.72 111.127 322.169 83.5758 288.165 83.5187Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M292.188 140.16C301.187 143.345 304.849 147.512 304.849 154.338C304.849 160.416 300.973 165.691 293.835 167.789C292.364 168.221 291.352 169.571 291.35 171.105V172.614C291.35 174.526 289.801 176.075 287.889 176.075H287.418C285.507 176.075 283.957 174.526 283.957 172.614V172.067C283.969 170.304 282.655 168.814 280.904 168.606C278.544 168.305 276.223 167.75 273.981 166.951C272.287 166.34 271.334 164.542 271.78 162.798L272.299 160.762C272.543 159.801 273.188 158.99 274.069 158.536C274.951 158.082 275.985 158.028 276.91 158.388C279.784 159.486 282.832 160.06 285.909 160.084C290.229 160.084 293.185 158.415 293.185 155.383C293.185 152.504 290.762 150.683 285.148 148.786C277.034 146.059 271.503 142.265 271.503 134.941C271.503 128.966 275.269 124.148 281.887 122.065C283.334 121.616 284.322 120.278 284.324 118.762V117.724C284.324 115.812 285.873 114.263 287.785 114.263H288.256C290.167 114.263 291.717 115.812 291.717 117.724C291.726 119.494 293.054 120.979 294.812 121.185C296.455 121.389 298.076 121.739 299.657 122.231C301.388 122.803 302.378 124.62 301.921 126.384L301.464 128.15C301.215 129.091 300.586 129.886 299.727 130.344C298.868 130.802 297.857 130.883 296.937 130.566C294.56 129.784 292.073 129.391 289.571 129.403C284.642 129.403 283.05 131.528 283.05 133.646C283.05 136.145 285.701 137.737 292.146 140.167"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M403.507 191C404.49 190.962 405.627 190.559 406.343 189.889L429.719 168.006C431.059 166.524 431.612 163.68 430.063 162.023C428.538 160.391 425.579 160.439 424.047 162.065L407.632 177.451V102.103C407.632 99.837 405.785 98 403.507 98C401.229 98 399.382 99.837 399.382 102.103V177.451L382.967 162.065C381.557 160.665 378.494 160.407 376.951 162.023C375.408 163.639 375.901 166.584 377.295 168.006L400.671 189.889C401.471 190.638 402.41 191.002 403.507 191Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M172.507 191C173.49 190.962 174.627 190.559 175.343 189.889L198.719 168.006C200.059 166.524 200.612 163.68 199.063 162.023C197.538 160.391 194.579 160.439 193.047 162.065L176.632 177.451V102.103C176.632 99.837 174.785 98 172.507 98C170.229 98 168.382 99.837 168.382 102.103V177.451L151.967 162.065C150.557 160.665 147.494 160.407 145.951 162.023C144.408 163.639 144.901 166.584 146.295 168.006L169.671 189.889C170.471 190.638 171.41 191.002 172.507 191Z"
        variants={iconPath}
        strokeWidth="5"
      />
    </motion.svg>
  );
}
