import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Conga({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconCircle = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#E53535",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#E53535",
      },
    },
  };
  const iconText = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#E53535",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#fff",
        stroke: "#fff",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconCircle}
        strokeWidth="10"
        d="M490 250C490 382.576 382.576 490 250 490C117.424 490 10 382.576 10 250C10 117.424 117.424 10 250 10C382.576 10 490 117.52 490 250Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M102.352 226.768C106.768 226.768 111.76 227.248 117.52 228.208L118.768 228.4L120.112 218.992L119.056 218.704C113.488 217.36 107.92 216.592 102.352 216.592C83.056 216.592 74.032 224.848 74.032 242.608V267.856C74.032 285.616 83.056 293.872 102.352 293.872C107.92 293.872 113.488 293.104 119.056 291.76L120.112 291.472L118.768 282.064L117.52 282.256C112.528 283.216 107.44 283.696 102.352 283.696C89.2 283.696 84.976 279.952 84.976 268.144V242.32C84.976 230.512 89.2 226.768 102.352 226.768Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M170.512 216.592C151.792 216.592 141.424 226.288 141.424 243.856V266.416C141.424 283.984 151.792 293.68 170.512 293.68C189.232 293.68 199.6 283.984 199.6 266.416V243.856C199.6 226.288 189.328 216.592 170.512 216.592ZM152.464 243.664C152.464 232.144 158.224 226.768 170.512 226.768C182.896 226.768 188.56 232.144 188.56 243.664V266.8C188.56 278.32 182.8 283.696 170.512 283.696C158.128 283.696 152.464 278.32 152.464 266.8V243.664Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M258.928 216.592C250.768 216.592 238.768 220.336 230.8 223.984L230.224 218.128H220.432V292.336H231.472V234.832C240.496 230.224 252.496 227.056 257.872 227.056C262.672 227.056 264.496 228.784 264.496 233.008V292.336H275.44V234.064C275.344 222.352 269.968 216.592 258.928 216.592Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M399.856 216.592C392.656 216.592 383.44 217.744 377.392 219.376L376.336 219.664L378.064 229.168L379.312 228.88C387.088 227.344 394.192 226.576 400.336 226.576C412.144 226.576 416.08 229.552 416.08 238.48V251.248H396.016C380.176 251.248 373.744 257.392 373.744 272.368C373.744 286.288 380.56 293.584 393.52 293.584C401.872 293.584 409.456 291.568 416.848 287.248L417.328 292.048H427.024V238.384C427.024 223.792 418.192 216.592 399.856 216.592ZM416.08 261.52V276.88C409.936 281.104 401.968 283.696 395.344 283.696C386.32 283.696 384.784 280.816 384.784 272.656C384.784 263.824 387.184 261.52 396.4 261.52H416.08Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M324.976 216.592C305.104 216.592 296.176 224.752 296.176 242.992V243.856C296.176 253.84 299.44 261.136 305.872 265.552C300.976 269.776 299.92 274.384 299.92 277.552C299.92 283.312 302.608 290.416 315.184 291.472C322.288 292.048 324.496 292.144 329.008 292.432L334.672 292.816C342.544 293.392 345.04 295.6 345.04 302.032C345.04 305.296 344.656 309.904 344.656 309.904V310.288H355.216V310C355.216 309.904 355.792 304.816 355.792 301.552C355.792 290.128 349.744 284.272 336.88 283.216L331.6 282.832C326.032 282.448 323.248 282.16 316.24 281.584C311.536 281.2 310.864 279.472 310.864 277.648C310.864 274.672 312.208 272.368 315.568 269.488C318.64 270.064 321.712 270.352 324.784 270.256C343.888 270.256 353.584 261.328 353.584 243.856V242.992C353.584 236.56 352.336 231.28 349.84 227.248L357.904 226.576V218.032H337.264C333.904 217.072 329.968 216.592 324.976 216.592ZM306.928 242.8C306.928 230.416 311.344 226.48 324.976 226.48C338.512 226.48 343.024 230.608 343.024 242.8V244.24C343.024 255.952 337.936 260.56 324.976 260.56C311.632 260.56 306.928 256.24 306.928 244.24V242.8Z"
      />
    </motion.svg>
  );
}
