import React from "react";
import Layout from "../components/Layout";
import Meta from "../components/Meta";
import HeroSection from "../components/HeroSection";
import * as styles from "../styles/services.module.css";
import * as heroStyles from "../styles/hero-section.module.css";
import LinearReveal from "../components/animation/LinearReveal";
import Card from "../components/Card";
import SalesCloud from "../components/icons/SalesCloud";
import ServiceCloud from "../components/icons/ServiceCloud";
import MarketingCloud from "../components/icons/MarketingCloud";
import ExperienceCloud from "../components/icons/ExperienceCloud";
import SalesforceCloud from "../components/icons/SalesforceCloud";
import Platform from "../components/icons/Platform";
import Mulesoft from "../components/icons/Mulesoft";
import Copado from "../components/icons/Copado";
import Conga from "../components/icons/Conga";
import Experts from "../components/icons/Experts";
import CustomerAlignment from "../components/icons/CustomerAlignment";
import HandCoin from "../components/icons/HandCoin";
import Success from "../components/icons/Success";
import Handshake from "../components/icons/Handshake";
import Gears from "../components/icons/Gears";
import Integration from "../components/icons/Integration";
import ScreeningCandidate from "../components/icons/ScreeningCandidate";
import Migration from "../components/icons/Migration";
import Development from "../components/icons/Development";
import Watch from "../components/icons/Watch";
import Manage from "../components/icons/Manage";
import Support from "../components/icons/Support";
import Erp from "../components/icons/Erp";
import Msoffice from "../components/icons/Msoffice";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import GovernmentCloud from "../components/icons/GovernmentCloud";
import Nintex from "../components/icons/Nintex";

export default function Salesforce() {
  const expertise = [
    {
      icon: <SalesCloud />,
      title: "Sales Cloud",
    },
    {
      icon: <ServiceCloud />,
      title: "Service Cloud",
    },
    {
      icon: <MarketingCloud />,
      title: "Marketing Cloud",
    },
    {
      icon: <ExperienceCloud />,
      title: "Experience Cloud",
    },
    {
      icon: <SalesforceCloud />,
      title: "App Exchange",
    },
    {
      icon: <Platform />,
      title: "Salesforce Platform",
    },
    {
      icon: <GovernmentCloud />,
      title: "Government Cloud",
    },
    {
      icon: <Mulesoft />,
      title: "Mulesoft",
    },
    {
      icon: <Copado />,
      title: "Copado",
    },
    {
      icon: <Conga />,
      title: "Conga",
    },

    {
      icon: <Nintex />,
      title: "Nintex",
    },
  ];

  const standOut = [
    {
      icon: <Experts />,
      title: "Certified Salesforce Experts",
    },
    {
      icon: <CustomerAlignment />,
      title: "Invest in Long Term Success",
    },
    {
      icon: <HandCoin />,
      title: "Cost Saving Offshore-Onsite Model",
    },
    {
      icon: <Success />,
      title: "100% Success Rate",
    },
  ];

  const services = [
    {
      icon: <Handshake />,
      title: "Salesforce Consulting",
    },
    {
      icon: <Gears />,
      title: "Salesforce App Development",
    },
    {
      icon: <Integration />,
      title: "Salesforce Integration",
    },
    {
      icon: <ScreeningCandidate />,
      title: "Salesforce Outsourcing",
    },
    {
      icon: <Migration />,
      title: "Data Migration",
    },
    {
      icon: <Development />,
      title: "Custom Development",
    },
    {
      icon: <Watch />,
      title: "Quick Starts",
    },
    {
      icon: <Manage />,
      title: "Managed Services",
    },
    {
      icon: <Support />,
      title: "Staff Augmentation",
    },
  ];
  return (
    <Layout>
      <Meta
        title="Salesforce - DBT"
        description="Develop Process and glean insights to nurture a superior network, manage deal flows, and achieve higher revenues"
      />
      <HeroSection
        title="Salesforce"
        titleClass={"text-primary " + heroStyles.heroTitle}
        description="Develop Process and glean insights to nurture a superior network, manage deal flows, and achieve higher revenues"
        descClass={"has-text-white-bis " + heroStyles.heroDesc}
        imageClass={heroStyles.salesforceImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />
      <main className="mx-auto" style={{ maxWidth: "1280px" }}>
        <LinearReveal>
          <h1 className={"text-primary has-text-centered " + styles.heading}>
            Salesforce and DBT - Exceptional Customer Experience
          </h1>
        </LinearReveal>

        <LinearReveal>
          <div className="px-5" style={{ maxWidth: "1000px", margin: "auto" }}>
            <p className={styles.description}>
              DBT is focused on helping Sales & Service teams optimize their workflow on
              Salesforce. We help the client harness the power of Salesforce so that they
              can win more deals and offer personalized support to keep customers happy.
            </p>
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "px-5 sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Cloud and Integration Expertise
          </h2>
          <div className="is-flex is-justify-content-center is-flex-wrap-wrap mx-auto">
            {expertise.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered pb-0 mb-2"
                  cardStyle={{ paddingTop: "30px", width: "250px" }}
                  cardContentClass="pb-2"
                />
              </div>
            ))}{" "}
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "px-5 sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            How we stand out
          </h2>
          <div className="is-flex is-justify-content-center is-flex-wrap-wrap mx-auto">
            {standOut.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered pb-0 mb-2"
                  cardStyle={{ paddingTop: "30px", width: "250px" }}
                  cardContentClass="pb-2 px-0"
                />
              </div>
            ))}
          </div>{" "}
        </LinearReveal>
        <LinearReveal>
          <div className="px-5" style={{ maxWidth: "1000px", margin: "auto" }}>
            <p className={styles.description2 + " mt-5"}>
              <strong className="text-heading">Competent Team -</strong> DBT has a
              talented pool of certified Salesforce consultants and technology experts who
              can address all your unique business needs. We help our clients to increase
              productivity, improve operational efficiency and enhance profitability.
            </p>
            <p className={styles.description2}>
              <strong className="text-heading">Impressive Track Record - </strong>We help
              clients successfully and seamlessly migrate, customize, and integrate
              Salesforce solutions with complete transparency and responsiveness and
              deliver a new digital experience for their customers.
            </p>
            <p className={styles.description2}>
              <strong className="text-heading">
                Invested in Long-Term Client Success -{" "}
              </strong>
              We are a trusted Salesforce advisor, collaborator, and long-standing
              partner. As we spend considerable time understanding our clients' strategic
              vision and growth plans, we are focused on meeting our client's objectives
              from the beginning of every project.
            </p>
            <p className={styles.description2}>
              <strong className="text-heading">Cost Saving Model - </strong>
              Offshore-Onsite Model to save cost while providing higher value with the
              follow-the-sun model of support & Development.
            </p>
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "px-5 sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Our Salesforce Services
          </h2>
          <div className="is-flex is-justify-content-center is-flex-wrap-wrap mx-auto">
            {services.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered pb-0 mb-2"
                  cardStyle={{ paddingTop: "30px", width: "250px" }}
                  cardContentClass="pb-2 px-1"
                />
              </div>
            ))}
          </div>{" "}
          <div className="columns mt-5 mx-auto" style={{ maxWidth: "1000px" }}>
            <div className="column mx-auto" style={{ maxWidth: "500px" }}>
              <Erp />
            </div>
            <div className="column mx-auto" style={{ maxWidth: "500px" }}>
              <Msoffice />
            </div>
          </div>
        </LinearReveal>
        <LinearReveal>
          <div className="px-5" style={{ maxWidth: "1000px", margin: "auto" }}>
            <p className={styles.description2 + " mt-5"}>
              <strong className="text-heading">Consulting - </strong>With our feasibility
              analysis and readiness assessment, our clients can identify gaps and address
              all customer-related operational bottlenecks potentially slowing their
              growth. <br />
              <Link to="/case-study/salesforce-integration">Learn More</Link>
            </p>
            <p className={styles.description2}>
              <strong className="text-heading">Salesforce App Development - </strong>
              DBT delivers custom Salesforce app development and can suggest the most
              suitable AppExchange apps to meet our clients' specific and unique
              requirements. <br />
              <Link to="/products/timesheet">Explore Timesheet App</Link> <br />
              <Link to="/products/ats">Explore ATS App</Link>
            </p>
            <p className={styles.description2}>
              <strong className="text-heading">Implementation Quick Starts - </strong> Our
              implementation services include Sales Cloud, Marketing Cloud, Service Cloud,
              Experience Cloud, and Salesforce Industry Cloud - Government Cloud and
              Financial Services Cloud.
              <br /> <br />
              Our typical small to midsize Sales Cloud implementation differentiator{" "}
              <br />
              <img
                src="/img/integrations.webp"
                alt="DBT Integrations"
                style={{ margin: "40px auto" }}
              />{" "}
              <br />
              The dates are all representative and assume no custom development. We will
              involve our Apex Developers in case there are custom development
              requirements and the timeline will shift based on the requirements.
            </p>
            <p className={styles.description2}>
              <strong className="text-heading">SAP Integration -</strong>We ensure
              seamless integration of the Salesforce app with SAP and other third-party
              apps with smooth data migration with critical validations and checks.
            </p>
            <p className={styles.description2}>
              <strong className="text-heading">Managed Services - </strong> To ensure a
              stable performance and rapid enhancement of the Salesforce solution, we
              offer comprehensive managed services to adjust to our clients' evolving
              requirements.{" "}
              <Link to="/case-study/salesforce-integration">Learn More</Link>
            </p>
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "px-5 sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Maximize Value from your Salesforce Investment
          </h2>
          <div className="columns px-5" style={{ maxWidth: "1000px", margin: "auto" }}>
            <div className="column p-0 is-flex is-justify-content-center is-align-items-center">
              <img
                src="/img/client-partner/consulting-partner.png"
                alt="Salesforce Consulting Partner"
                className="p-4"
              />
            </div>
            <div className="column p-0">
              <p className={styles.description2}>
                DBT is a boutique Salesforce Consulting Partner focused on Small and
                Medium companies. We are a trusted alliance with a small but efficient
                team of experienced Salesforce consultants committed to the success of our
                clients' projects. We help our clients leverage the maximum value from the
                Salesforce products by leveraging the combination of Offshore and Onsite
                resources.
              </p>

              <Link to="/contact-us">
                <motion.button
                  className="button px-4"
                  style={{ transitionDuration: "0s" }}
                  initial={{ border: "2px solid var(--secondary)", color: "#4a4a4a" }}
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "var(--secondary)",
                    color: "#fff",
                  }}
                >
                  Contact Us
                </motion.button>
              </Link>
            </div>
          </div>
        </LinearReveal>
      </main>
    </Layout>
  );
}
