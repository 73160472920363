import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Nintex({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconBlack = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#091820",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#091820",
      },
    },
  };
  const iconOrange = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#FF6D00",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#FF6D00",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconBlack}
        strokeWidth="15"
        d="M348.441 249.187L490 390.746L390.746 490L151.56 249.187L390.746 10L490 107.627L348.441 249.187Z"
      />
      <motion.path
        variants={iconOrange}
        strokeWidth="15"
        d="M348.441 249.187L109.254 490L10 390.746L151.559 249.187L10 107.627L109.254 10L348.441 249.187Z"
      />
    </motion.svg>
  );
}