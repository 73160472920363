import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function ServiceCloud({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconInnerHeart = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#D3679D",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#D3679D",
      },
    },
  };
  const iconOuterHeart = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#812124",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#812124",
      },
    },
  };
  const iconWhitePart = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#fff",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#fff",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconInnerHeart}
         strokeWidth="15"
        d="M441.946 94.0529C422.146 74.2716 395.303 63.1602 367.315 63.1602C339.326 63.1602 312.483 74.2716 292.683 94.0529L248.929 137.807L205.174 94.0529C185.374 74.2716 158.531 63.1602 130.543 63.1602C102.554 63.1602 75.7109 74.2716 55.9108 94.0529C36.1295 113.853 25.0181 140.696 25.0181 168.685C25.0181 196.673 36.1295 223.516 55.9108 243.317L248.929 436.42L441.946 243.403C451.754 233.597 459.534 221.955 464.842 209.142C470.15 196.33 472.882 182.597 472.882 168.728C472.882 154.859 470.15 141.126 464.842 128.313C459.534 115.5 451.754 103.859 441.946 94.0529Z"
      />
      <motion.path
        variants={iconOuterHeart}
         strokeWidth="15"
        d="M248.929 453.647C244.367 453.628 240 451.801 236.784 448.566L43.7664 255.548C21.7106 232.326 9.59786 201.407 10.0102 169.383C10.4225 137.359 23.3273 106.763 45.9737 84.1165C68.62 61.4702 99.2164 48.5654 131.24 48.1531C163.265 47.7408 194.183 59.8535 217.405 81.9093L248.929 113.433L280.452 81.9093C303.495 58.9144 334.718 46 367.271 46C399.825 46 431.048 58.9144 454.091 81.9093C477.086 104.952 490 136.175 490 168.729C490 201.282 477.086 232.505 454.091 255.548L261.073 448.566C257.857 451.801 253.49 453.628 248.929 453.647ZM130.586 80.4451C113.112 80.4206 96.0243 85.5821 81.4861 95.2757C66.9479 104.969 55.6133 118.759 48.9176 134.899C42.2218 151.039 40.4662 168.803 43.873 185.941C47.2798 203.079 55.6957 218.821 68.0551 231.173L248.929 412.046L429.802 231.173C445.664 214.459 454.37 192.211 454.065 169.171C453.76 146.131 444.467 124.121 428.168 107.833C411.869 91.5453 389.853 82.268 366.813 81.9787C343.772 81.6893 321.53 90.4108 304.827 106.284L261.073 150.038C257.779 153.152 253.418 154.886 248.886 154.886C244.353 154.886 239.992 153.152 236.698 150.038L192.944 106.284C184.758 98.09 175.037 91.5902 164.337 87.1565C153.637 82.7227 142.168 80.4421 130.586 80.4451Z"
      />
      <motion.path
        variants={iconWhitePart}
         strokeWidth="15"
        d="M93.8943 192.328C89.3256 192.328 84.9441 190.513 81.7136 187.282C78.4831 184.052 76.6682 179.67 76.6682 175.102C76.6682 159.111 83.0203 143.776 94.3271 132.469C105.634 121.163 120.969 114.811 136.959 114.811C141.528 114.811 145.91 116.625 149.14 119.856C152.371 123.086 154.185 127.468 154.185 132.037C154.185 136.605 152.371 140.987 149.14 144.217C145.91 147.448 141.528 149.263 136.959 149.263C130.106 149.263 123.534 151.985 118.688 156.831C113.843 161.676 111.12 168.249 111.12 175.102C111.12 179.67 109.305 184.052 106.075 187.282C102.844 190.513 98.4629 192.328 93.8943 192.328Z"
      />
    </motion.svg>
  );
}

