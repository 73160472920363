import React from "react";

export default function ERP() {
  return (
    <svg
      width="500"
      height="68"
      viewBox="0 0 500 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ maxWidth: "100%" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M406.303 21.9316H407.732C408.328 21.9316 408.447 22.2885 408.447 22.646C408.447 22.7657 408.447 23.4795 408.566 23.837H409.281V23.5991C409.162 23.4795 409.042 23.4795 409.042 23.2415V22.5271C409.042 21.9316 408.805 21.8126 408.566 21.6937C408.923 21.4558 409.162 21.2172 409.162 20.5034C409.162 19.67 408.447 19.3124 407.732 19.3124H405.708V23.8376H406.303V21.9316ZM406.303 19.9079H407.732C408.089 19.9079 408.565 19.9079 408.565 20.6224C408.565 21.2178 408.089 21.3368 407.613 21.3368H406.303V19.9079ZM289.491 17.2881H401.54L344.265 57.0864H289.491V17.2881Z"
        fill="#014076"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M407.375 25.7416C409.518 25.7416 411.424 23.8369 411.424 21.455C411.424 19.1927 409.518 17.4062 407.375 17.4062C405.113 17.4062 403.089 19.192 403.089 21.455C403.089 23.8369 405.113 25.7416 407.375 25.7416ZM407.375 25.1468C405.233 25.1468 403.684 23.5989 403.684 21.4556C403.684 19.5502 405.233 18.0024 407.375 18.0024C408.291 18.0029 409.169 18.3669 409.816 19.0144C410.464 19.6619 410.828 20.5399 410.828 21.4556C410.828 23.5989 409.281 25.1468 407.375 25.1468Z"
        fill="#014076"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M350.636 42.5344H356.481C365.086 42.5344 366.71 35.2278 366.385 33.1173C365.898 27.9221 362.002 24.6743 356.643 24.6743H342.355V50.8142H350.636V42.5344ZM350.636 36.3642V30.6814H354.208C357.13 30.6814 358.266 31.3312 358.266 33.4417C358.266 34.4158 358.266 36.3642 355.019 36.3642H350.636Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M324.334 45.2937H332.615L334.725 50.8133H343.492L333.588 24.6743H323.684L313.618 50.8142H322.061L324.334 45.2937ZM328.718 31.9809L331.64 39.7732H325.959L328.718 31.9809Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.212 42.5343V43.996C291.212 49.1912 297.543 51.1395 303.551 51.1395C311.669 51.1395 316.541 48.217 316.541 42.5352C316.541 33.4425 300.305 36.2028 300.305 30.8454C300.305 28.8962 301.766 28.2473 304.039 28.2473C305.5 28.2473 305.987 28.5717 306.474 28.8971C307.448 29.5468 307.448 30.3587 307.448 31.8195H316.053V31.1698C316.053 27.2732 312.643 24.0254 304.039 24.0254C294.622 24.0254 291.699 27.5976 291.699 31.8186C291.699 41.0735 307.936 38.1502 307.936 43.8329C307.936 44.9692 306.961 46.5931 303.551 46.5931C300.791 46.5931 299.817 45.2946 299.817 43.3454V42.5343H291.212Z"
        fill="white"
      />
      <rect x="2" y="2" width="496" height="63.7966" stroke="#234B8D" strokeWidth="4" />
      <rect width="225.5" height="67.7966" fill="#014076" />
      <path
        d="M84.0227 48V24.7273H99.7045V28.7841H88.9432V34.3295H98.8977V38.3864H88.9432V43.9432H99.75V48H84.0227ZM103.616 48V24.7273H112.798C114.556 24.7273 116.056 25.0417 117.298 25.6705C118.548 26.2917 119.499 27.1742 120.151 28.3182C120.81 29.4545 121.139 30.7917 121.139 32.3295C121.139 33.875 120.806 35.2045 120.139 36.3182C119.473 37.4242 118.507 38.2727 117.241 38.8636C115.984 39.4545 114.461 39.75 112.673 39.75H106.526V35.7955H111.878C112.817 35.7955 113.598 35.6667 114.219 35.4091C114.84 35.1515 115.302 34.7652 115.605 34.25C115.916 33.7348 116.071 33.0947 116.071 32.3295C116.071 31.5568 115.916 30.9053 115.605 30.375C115.302 29.8447 114.836 29.4432 114.207 29.1705C113.586 28.8902 112.802 28.75 111.855 28.75H108.537V48H103.616ZM116.185 37.4091L121.969 48H116.537L110.878 37.4091H116.185ZM124.616 48V24.7273H133.798C135.563 24.7273 137.067 25.0644 138.31 25.7386C139.552 26.4053 140.499 27.3333 141.151 28.5227C141.81 29.7045 142.139 31.0682 142.139 32.6136C142.139 34.1591 141.806 35.5227 141.139 36.7045C140.473 37.8864 139.507 38.8068 138.241 39.4659C136.984 40.125 135.461 40.4545 133.673 40.4545H127.821V36.5114H132.878C133.825 36.5114 134.605 36.3485 135.219 36.0227C135.84 35.6894 136.302 35.2311 136.605 34.6477C136.916 34.0568 137.071 33.3788 137.071 32.6136C137.071 31.8409 136.916 31.1667 136.605 30.5909C136.302 30.0076 135.84 29.5568 135.219 29.2386C134.598 28.9129 133.81 28.75 132.855 28.75H129.537V48H124.616Z"
        fill="white"
      />
    </svg>
  );
}
