import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Copado({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconFrame = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#00C3EF",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#00C3EF",
      },
    },
  };
  const iconWhitePart = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#fff",
      transition: {
        duration: 2,
        ease: "easeInOut",
        },
        transitionEnd: {
          fill: "#fff"
      }
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        d="M250.333 490C383.065 490 490.666 382.548 490.666 250C490.666 117.451 383.065 10 250.333 10C117.601 10 10 117.451 10 250C10 382.548 117.601 490 250.333 490Z"
        variants={iconFrame}
        strokeWidth="15"
      />
      <motion.path
        d="M342.836 158.358C329.468 158.344 316.256 161.218 304.107 166.783C291.957 172.349 281.157 180.474 272.448 190.601V190.497L272.137 190.808C270.061 193.193 268.192 195.68 266.428 198.168L246.494 223.983L203.204 279.965L198.428 286.185L195.521 290.021C190.616 294.953 184.774 298.86 178.34 301.512C171.905 304.165 165.005 305.51 158.043 305.468C128.56 305.468 104.371 282.35 104.371 249.901C104.371 217.452 128.56 194.333 158.043 194.333C167.374 194.293 176.547 196.742 184.614 201.425C192.68 206.109 199.349 212.859 203.93 220.976L229.261 191.845C220.598 181.363 209.72 172.927 197.407 167.138C185.095 161.347 171.653 158.35 158.043 158.358C106.967 158.358 65.5443 199.516 65.5443 250.004C65.5443 300.493 106.967 341.65 158.043 341.65C180.882 341.621 202.894 333.123 219.814 317.806L297.572 219.421V219.628C302.309 211.872 308.978 205.474 316.929 201.059C324.881 196.644 333.842 194.361 342.939 194.437C372.215 194.437 396.3 217.555 396.3 250.004C396.3 282.454 372.111 305.572 342.939 305.572C313.455 305.572 289.267 282.454 289.267 250.004L258.641 287.638C266.022 303.747 277.879 317.4 292.805 326.975C307.73 336.55 325.095 341.644 342.836 341.65C393.705 341.65 435.127 300.493 435.127 250.004C435.127 199.516 393.705 158.358 342.836 158.358Z"
        variants={iconWhitePart}
        strokeWidth="5"
      />
      <motion.path
        d="M342.729 267.406C351.157 267.406 357.99 260.583 357.99 252.167C357.99 243.75 351.157 236.927 342.729 236.927C334.301 236.927 327.468 243.75 327.468 252.167C327.468 260.583 334.301 267.406 342.729 267.406Z"
        variants={iconWhitePart}
        strokeWidth="5"
      />
    </motion.svg>
  );
}
