import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Watch({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M264.85 254.2V145H234.85V254.2C225.82 259.48 219.85 269.14 219.85 280.18C219.85 296.74 233.29 310.18 249.85 310.18C266.41 310.18 279.85 296.74 279.85 280.18C279.85 269.14 273.88 259.48 265 254.29L264.85 254.2Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M249.851 70C133.871 70 39.8506 164.02 39.8506 280C39.8506 395.98 133.871 490 249.851 490C365.831 490 459.851 395.98 459.851 280C459.851 164.02 365.831 70 249.851 70ZM249.851 445C158.711 445 84.8506 371.14 84.8506 280C84.8506 188.86 158.711 115 249.851 115C340.991 115 414.851 188.86 414.851 280C414.671 371.05 340.901 444.82 249.881 445H249.851Z"
      />
      <motion.path
        d="M189.851 10H309.851V55H189.851V10Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M10 143.14L91.39 54.9697L124.45 85.4797L43.06 173.65L10 143.14Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M375.431 85.5696L408.491 55.0596L489.881 143.23L456.821 173.74L375.431 85.5696Z"
      />
    </motion.svg>
  );
}
