import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Success({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        variants={iconPath}
        d="M199.554 115.176C193.814 129.791 177.126 137.071 162.282 131.452C147.48 125.839 140.11 109.366 145.857 94.7815C151.597 80.1661 168.255 72.886 183.056 78.5054C197.925 84.1555 205.264 100.56 199.554 115.176Z"
      />
      <motion.path
        variants={iconPath}
        d="M196.105 262.249C199.236 262.157 202.997 262.794 207.469 264.326C225.401 270.387 232.833 278.856 237.268 286.675C242.69 296.241 244.099 311.506 245.153 338.249C245.281 340.455 245.857 342.269 246.715 343.868L217.621 360.653C202.458 369.428 193.048 372.59 174.608 372.59H173.683C176.813 339.842 180.226 308.852 182.302 280.418C183.087 269.565 186.762 262.537 196.105 262.249ZM338.671 11.0349C337.587 10.8817 336.19 11.231 334.554 12.1196C305.779 27.6603 278.363 20.2576 249.269 35.7739C248.185 33.7577 246.041 32.5444 243.78 32.5812C239.633 32.6486 235.645 37.1711 236.949 41.1359L277.855 173.11C267.324 183.577 259.255 186.451 249.619 185.145C239.792 183.803 227.57 176.591 214.166 160.216C204.271 148.181 189.979 144.706 178.045 144.161C166.141 143.591 155.163 144.964 147.223 144.13C130.406 142.408 117.7 134.148 108.106 120.703C98.5001 107.294 92.6617 88.4384 89.6598 67.5908C87.1051 49.8439 66.3613 56.0026 67.0659 68.4793C69.9085 119.195 95.2716 164.426 147.254 174.544C155.101 226.118 156.027 294.139 148.846 372.584H142.855C117.866 372.584 111.488 377.658 92.0859 396.171L12.8538 471.693C7.65871 476.669 9.50274 489.979 18.827 489.979H482.775C489.759 489.979 493.141 478.747 486.157 471.693L339.125 323.854C323.999 308.632 303.322 311.157 281.372 323.854L269.493 330.687C268.567 307.357 267.428 289.831 258.71 274.511C250.188 259.509 234.137 246.843 211.041 236.885L207.592 189.263C219.79 200.655 232.643 207.703 246.304 209.59C260.566 211.533 273.456 207.549 285.776 198.639L311.305 280.951C313.884 289.255 326.713 285.137 324.127 276.833L297.067 189.478C297.098 189.447 297.135 189.447 297.135 189.41C305.589 181.144 299.113 169.905 290.5 168.25L267.716 94.7135C296.847 79.2402 324.195 86.5081 353.166 71.0041C357.571 68.6448 357.632 67.1066 356.352 63.0192L340.883 12.9714C340.491 11.8315 339.756 11.1942 338.671 11.0349Z"
      />
    </motion.svg>
  );
}
