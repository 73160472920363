import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Mulesoft({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#00A1DF",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#00A1DF",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        d="M250.074 469.953C129.15 469.953 31.1395 372.049 31.1395 251.257C30.9917 130.316 129.002 32.4119 250.074 32.4119C370.998 32.4119 469.008 130.316 469.008 251.109C469.008 371.902 370.998 469.953 250.074 469.953ZM249.926 11C117.619 11 10 118.06 10 249.632C10 381.352 117.619 488.412 249.926 488.412C382.233 488.412 490 381.352 490 249.78C490 118.06 382.381 11 249.926 11Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M189.169 356.84C152.951 339.858 127.82 301.464 127.82 257.016C127.82 233.537 134.768 211.83 146.742 194.109L225.683 311.653H273.431L352.372 194.109C364.346 211.83 371.294 233.685 371.294 257.016C371.294 298.511 349.415 334.542 317.189 353.148L332.415 411.33C390.364 381.501 429.834 321.252 429.834 251.7C429.834 185.988 394.503 128.692 341.876 97.3866L250.222 238.705L159.455 96.7959C106.237 127.954 70.4624 185.692 70.4624 251.7C70.4624 323.762 112.889 385.931 174.238 414.431L189.169 356.84Z"
        variants={iconPath}
        strokeWidth="5"
      />
    </motion.svg>
  );
}
