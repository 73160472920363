import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function GovernmentCloud({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#4B2048",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#4B2048",
      },
    },
  };
  const iconFill = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#9F63C6",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#9F63C6",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M395.208 417.547H105.396C100.592 417.547 95.9853 415.639 92.5884 412.242C89.1916 408.845 87.2832 404.238 87.2832 399.434V200.189C87.2832 195.385 89.1916 190.778 92.5884 187.381C95.9853 183.984 100.592 182.076 105.396 182.076H395.208C400.012 182.076 404.619 183.984 408.016 187.381C411.413 190.778 413.321 195.385 413.321 200.189V399.434C413.321 404.238 411.413 408.845 408.016 412.242C404.619 415.639 400.012 417.547 395.208 417.547ZM123.51 381.321H377.095V218.302H123.51V381.321Z"
      />
      <motion.path
        variants={iconFill}
        strokeWidth="5"
        d="M105.396 200.189C105.396 161.757 120.663 124.9 147.838 97.7251C175.013 70.55 211.87 55.2832 250.302 55.2832C288.733 55.2832 325.59 70.55 352.765 97.7251C379.941 124.9 395.207 161.757 395.207 200.189H105.396Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M395.208 218.302H105.396C100.592 218.302 95.9853 216.394 92.5884 212.997C89.1916 209.6 87.2832 204.993 87.2832 200.189C87.2832 156.954 104.458 115.489 135.03 84.917C165.602 54.3451 207.067 37.1699 250.302 37.1699C293.537 37.1699 335.002 54.3451 365.574 84.917C396.146 115.489 413.321 156.954 413.321 200.189C413.321 204.993 411.413 209.6 408.016 212.997C404.619 216.394 400.012 218.302 395.208 218.302ZM124.778 182.076H375.827C371.452 151.917 356.362 124.343 333.319 104.402C310.276 84.4599 280.821 73.4844 250.347 73.4844C219.873 73.4844 190.419 84.4599 167.376 104.402C144.333 124.343 129.243 151.917 124.868 182.076H124.778Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M250.302 73.3962C245.498 73.3962 240.891 71.4879 237.494 68.091C234.097 64.6941 232.188 60.0869 232.188 55.283V28.1132C232.188 23.3093 234.097 18.7021 237.494 15.3052C240.891 11.9083 245.498 10 250.302 10C255.106 10 259.713 11.9083 263.11 15.3052C266.507 18.7021 268.415 23.3093 268.415 28.1132V55.283C268.415 60.0869 266.507 64.6941 263.11 68.091C259.713 71.4879 255.106 73.3962 250.302 73.3962Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M322.755 417.547C317.951 417.547 313.344 415.639 309.947 412.242C306.55 408.845 304.642 404.238 304.642 399.434V200.189C304.642 195.385 306.55 190.778 309.947 187.381C313.344 183.984 317.951 182.076 322.755 182.076C327.559 182.076 332.166 183.984 335.563 187.381C338.96 190.778 340.868 195.385 340.868 200.189V399.434C340.868 404.238 338.96 408.845 335.563 412.242C332.166 415.639 327.559 417.547 322.755 417.547Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M250.302 417.547C245.498 417.547 240.891 415.639 237.494 412.242C234.097 408.845 232.188 404.238 232.188 399.434V200.189C232.188 195.385 234.097 190.778 237.494 187.381C240.891 183.984 245.498 182.076 250.302 182.076C255.106 182.076 259.713 183.984 263.11 187.381C266.507 190.778 268.415 195.385 268.415 200.189V399.434C268.415 404.238 266.507 408.845 263.11 412.242C259.713 415.639 255.106 417.547 250.302 417.547Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M177.849 417.547C173.045 417.547 168.438 415.639 165.041 412.242C161.644 408.845 159.736 404.238 159.736 399.434V200.189C159.736 195.385 161.644 190.778 165.041 187.381C168.438 183.984 173.045 182.076 177.849 182.076C182.653 182.076 187.26 183.984 190.657 187.381C194.054 190.778 195.962 195.385 195.962 200.189V399.434C195.962 404.238 194.054 408.845 190.657 412.242C187.26 415.639 182.653 417.547 177.849 417.547Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M440.491 218.302H60.1132C55.3093 218.302 50.7021 216.394 47.3052 212.997C43.9084 209.6 42 204.993 42 200.189C42 195.385 43.9084 190.778 47.3052 187.381C50.7021 183.984 55.3093 182.076 60.1132 182.076H440.491C445.294 182.076 449.902 183.984 453.299 187.381C456.695 190.778 458.604 195.385 458.604 200.189C458.604 204.993 456.695 209.6 453.299 212.997C449.902 216.394 445.294 218.302 440.491 218.302Z"
      />
      <motion.path
        variants={iconFill}
        strokeWidth="5"
        d="M440.491 399.434H60.1133V471.887H440.491V399.434Z"
      />
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M440.491 490H60.1132C55.3093 490 50.7021 488.092 47.3052 484.695C43.9084 481.298 42 476.691 42 471.887V399.434C42 394.63 43.9084 390.023 47.3052 386.626C50.7021 383.229 55.3093 381.321 60.1132 381.321H440.491C445.294 381.321 449.902 383.229 453.299 386.626C456.695 390.023 458.604 394.63 458.604 399.434V471.887C458.604 476.691 456.695 481.298 453.299 484.695C449.902 488.092 445.294 490 440.491 490ZM78.2264 453.774H422.377V417.547H78.2264V453.774Z"
      />
    </motion.svg>
  );
}
