import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Experts({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        d="M146.767 99.4309C146.767 127.094 124.354 149.515 96.7113 149.515C69.0689 149.515 46.6553 127.094 46.6553 99.4309C46.6553 71.7673 69.0689 49.3467 96.7113 49.3467C124.354 49.3467 146.767 71.7673 146.767 99.4309Z"
        strokeWidth="12"
        variants={iconPath}
      />
      <motion.path
        d="M49.8098 312.927L49.7482 312.91L49.6863 312.894L45.7123 311.901C37.1288 309.531 31 301.727 31 292.947V218.168C31 195.753 49.4845 177.684 71.4608 177.684H121.962C129.963 177.684 137.588 179.985 144.019 184.274C143.435 187.311 143.085 190.401 142.9 193.473H141.717V199.473V279.81C141.717 291.043 144.839 301.515 150.415 310.541C149.245 311.115 148.028 311.568 146.766 311.884L142.896 312.852C134.147 314.554 129.092 322.449 129.092 330.336V441.494C129.092 449.804 122.18 456.715 113.882 456.715H78.5309C70.2323 456.715 63.3206 449.804 63.3206 441.494V330.336C63.3206 321.965 57.8698 315.126 49.8098 312.927Z"
        strokeWidth="12"
        variants={iconPath}
      />
      <motion.path
        d="M454.822 99.4309C454.822 127.094 432.409 149.515 404.766 149.515C377.124 149.515 354.71 127.094 354.71 99.4309C354.71 71.7673 377.124 49.3467 404.766 49.3467C432.409 49.3467 454.822 71.7673 454.822 99.4309Z"
        strokeWidth="12"
        variants={iconPath}
      />
      <motion.path
        d="M357.865 312.422L357.804 312.405L357.742 312.389L353.702 311.379C352.44 311.063 351.223 310.61 350.053 310.036C355.629 301.01 358.751 290.538 358.751 279.305V199.473C358.751 194.518 358.432 189.386 357.483 184.398C363.986 180.23 371.31 177.684 379.516 177.684H430.017C452.55 177.684 470.339 195.747 469.473 217.423L469.468 217.543V217.663V292.442C469.468 301.222 463.339 309.026 454.756 311.395L450.952 312.347C442.203 314.049 437.147 321.944 437.147 329.831V440.989C437.147 449.299 430.236 456.21 421.937 456.21H386.586C378.288 456.21 371.376 449.299 371.376 440.989V329.831C371.376 321.46 365.925 314.621 357.865 312.422Z"
        strokeWidth="12"
        variants={iconPath}
      />
      <motion.path
        d="M200.179 300.77L200.103 300.751L200.026 300.734L195.563 299.742C186.198 297.364 179.473 288.704 179.473 278.801V198.97C179.473 174.491 199.515 154.443 223.974 154.443H277.505C301.887 154.443 322.006 174.919 322.006 199.475V279.812C322.006 289.714 315.281 298.374 305.916 300.752L301.453 301.744L301.376 301.761L301.299 301.781C292.684 303.936 286.655 311.299 286.655 320.233V467.264C286.655 476.585 279.238 484.001 269.93 484.001H231.549C222.24 484.001 214.824 476.585 214.824 467.264V319.222C214.824 310.289 208.795 302.925 200.179 300.77Z"
        strokeWidth="12"
        variants={iconPath}
      />
      <motion.path
        d="M305.845 71.1369C305.845 101.591 281.17 126.274 250.739 126.274C220.307 126.274 195.633 101.591 195.633 71.1369C195.633 40.6828 220.307 16 250.739 16C281.17 16 305.845 40.6828 305.845 71.1369Z"
        strokeWidth="12"
        variants={iconPath}
      />
    </motion.svg>
  );
}

<svg
  width="500"
  height="500"
  viewBox="0 0 500 500"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
></svg>;
