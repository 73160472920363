import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function SalesCloud({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconBg = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#13CEC0",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#13CEC0",
      },
    },
  };
  const iconFrame = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#067065",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#067065",
      },
    },
  };
  const iconArrow = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#fff",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#fff",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        strokeWidth="15"
        variants={iconBg}
        d="M29.2 29.2002H470.8V470.8H29.2V29.2002Z"
      />
      <motion.path
        strokeWidth="15"
        variants={iconArrow}
        d="M365.2 288.4C360.108 288.4 355.224 286.377 351.624 282.776C348.023 279.175 346 274.292 346 269.2V154H230.8C225.708 154 220.824 151.977 217.224 148.376C213.623 144.775 211.6 139.892 211.6 134.8C211.6 129.707 213.623 124.824 217.224 121.223C220.824 117.622 225.708 115.6 230.8 115.6H365.2C370.292 115.6 375.176 117.622 378.777 121.223C382.377 124.824 384.4 129.707 384.4 134.8V269.2C384.4 274.292 382.377 279.175 378.777 282.776C375.176 286.377 370.292 288.4 365.2 288.4Z"
      />
      <motion.path
        strokeWidth="15"
        variants={iconArrow}
        d="M29.2002 336.4C25.4145 336.384 21.7181 335.249 18.5755 333.139C15.4328 331.028 12.9844 328.035 11.5378 324.537C10.0912 321.038 9.71117 317.19 10.4454 313.477C11.1796 309.763 12.9953 306.349 15.6642 303.664L121.264 198.064C124.862 194.488 129.728 192.481 134.8 192.481C139.873 192.481 144.739 194.488 148.336 198.064L211.6 260.752L342.064 130.864C345.667 127.261 350.553 125.237 355.648 125.237C360.743 125.237 365.63 127.261 369.232 130.864C372.835 134.467 374.859 139.353 374.859 144.448C374.859 149.543 372.835 154.429 369.232 158.032L225.712 301.456C222.115 305.032 217.249 307.039 212.176 307.039C207.104 307.039 202.238 305.032 198.64 301.456L134.8 238.672L42.6402 330.832C39.064 334.379 34.2371 336.379 29.2002 336.4Z"
      />
      <motion.path
        strokeWidth="15"
        variants={iconFrame}
        d="M470.8 490H29.2C24.1078 490 19.2242 487.977 15.6235 484.376C12.0229 480.776 10 475.892 10 470.8V29.2C10 24.1078 12.0229 19.2242 15.6235 15.6235C19.2242 12.0229 24.1078 10 29.2 10H470.8C475.892 10 480.776 12.0229 484.376 15.6235C487.977 19.2242 490 24.1078 490 29.2V470.8C490 475.892 487.977 480.776 484.376 484.376C480.776 487.977 475.892 490 470.8 490ZM48.4 451.6H451.6V48.4H48.4V451.6Z"
      />
      <motion.path
        strokeWidth="15"
        variants={iconFrame}
        d="M134.8 490C129.708 490 124.824 487.977 121.224 484.376C117.623 480.776 115.6 475.892 115.6 470.8V394C115.6 388.908 117.623 384.024 121.224 380.423C124.824 376.823 129.708 374.8 134.8 374.8C139.892 374.8 144.776 376.823 148.377 380.423C151.977 384.024 154 388.908 154 394V470.8C154 475.892 151.977 480.776 148.377 484.376C144.776 487.977 139.892 490 134.8 490ZM250 490C244.908 490 240.024 487.977 236.424 484.376C232.823 480.776 230.8 475.892 230.8 470.8V394C230.8 388.908 232.823 384.024 236.424 380.423C240.024 376.823 244.908 374.8 250 374.8C255.092 374.8 259.976 376.823 263.577 380.423C267.177 384.024 269.2 388.908 269.2 394V470.8C269.2 475.892 267.177 480.776 263.577 484.376C259.976 487.977 255.092 490 250 490ZM365.2 490C360.108 490 355.224 487.977 351.624 484.376C348.023 480.776 346 475.892 346 470.8V394C346 388.908 348.023 384.024 351.624 380.423C355.224 376.823 360.108 374.8 365.2 374.8C370.292 374.8 375.176 376.823 378.777 380.423C382.377 384.024 384.4 388.908 384.4 394V470.8C384.4 475.892 382.377 480.776 378.777 484.376C375.176 487.977 370.292 490 365.2 490Z"
      />
    </motion.svg>
  );
}
