import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function ExperienceCloud({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconOuterFrame = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#032D60",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#032D60",
      },
    },
  };
  const iconInnerFrame = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#0D9DDA",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#0D9DDA",
      },
    },
  };
  const iconWhitePart = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#fff",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#fff",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        d="M162.836 272.222C148.239 258.484 138.794 237.877 138.794 215.553C138.794 172.621 173.139 138.276 216.071 138.276C259.002 138.276 293.347 172.621 293.347 215.553C293.347 237.877 283.902 257.625 269.306 271.363C278.751 276.515 287.337 283.384 295.065 291.112C305.368 301.415 313.954 313.436 319.106 327.174V207.825C319.106 183.783 338.855 164.035 362.896 164.035H370.624H387.796V95.3447H44.3452V335.761H61.5178H109.601C119.046 308.284 137.936 285.101 162.836 272.222Z"
        variants={iconInnerFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M121.622 155.449C112.177 155.449 104.449 163.176 104.449 172.621V189.794C104.449 199.239 96.7215 206.966 87.2766 206.966C77.8317 206.966 70.104 199.239 70.104 189.794V172.621C70.104 144.286 93.287 121.104 121.622 121.104C131.067 121.104 138.794 128.831 138.794 138.276C138.794 147.721 131.067 155.449 121.622 155.449Z"
        variants={iconInnerFrame}
         strokeWidth="5"
      />
      <motion.path
        variants={iconWhitePart}
         strokeWidth="5"
        d="M259.002 215.553C259.002 191.512 240.112 172.622 216.071 172.622C192.029 172.622 173.139 191.512 173.139 215.553C173.139 239.595 192.029 258.485 216.071 258.485C240.112 258.485 259.002 239.595 259.002 215.553Z"
      />
      <motion.path
        variants={iconWhitePart}
         strokeWidth="5"
        d="M216.071 292.829C186.019 292.829 159.401 310.002 146.522 335.76H284.761C281.326 328.033 276.175 321.164 270.164 315.153C256.426 300.557 236.678 292.829 216.071 292.829Z"
      />
      <motion.path
        d="M447.042 198.381H422.141H404.969H387.796H370.624H362.896C357.744 198.381 353.451 202.674 353.451 207.826V335.761V370.106V395.007C353.451 400.158 357.744 404.452 362.896 404.452H446.183C451.335 404.452 455.628 400.158 455.628 395.007V207.826C456.487 202.674 452.193 198.381 447.042 198.381Z"
        variants={iconInnerFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M404.969 387.279C395.524 387.279 387.796 379.551 387.796 370.106C387.796 360.661 395.524 352.934 404.969 352.934C410.979 352.934 416.99 356.368 419.566 361.52C421.283 364.096 422.142 366.672 422.142 370.106C422.142 379.551 414.414 387.279 404.969 387.279Z"
        variants={iconInnerFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M447.042 164.035H422.141V78.1726C422.141 68.7277 414.414 61 404.969 61H27.1726C17.7277 61 10 68.7277 10 78.1726V352.933C10 362.378 17.7277 370.106 27.1726 370.106H198.898V395.865H155.967C146.522 395.865 138.794 403.593 138.794 413.037C138.794 422.482 146.522 430.21 155.967 430.21H198.898H233.243H276.175C285.62 430.21 293.347 422.482 293.347 413.037C293.347 403.593 285.62 395.865 276.175 395.865H233.243V370.106H319.106V395.006C319.106 419.048 338.854 438.796 362.896 438.796H446.183C470.225 438.796 489.973 419.048 489.973 395.006V207.825C490.832 183.784 471.083 164.035 447.042 164.035Z"
        variants={iconOuterFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M456.487 395.007C456.487 400.158 452.193 404.452 447.042 404.452H363.755C358.603 404.452 354.31 400.158 354.31 395.007V370.106V335.761V207.826C354.31 202.674 358.603 198.381 363.755 198.381H370.624H387.796H404.969H422.141H447.042C452.193 198.381 456.487 202.674 456.487 207.826V395.007Z"
        variants={iconInnerFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M61.5178 335.761H44.3452V95.3447H387.796V164.035H370.624H362.896C338.855 164.035 319.106 183.783 319.106 207.825V327.174C313.954 314.295 305.368 301.415 295.065 291.112C287.337 283.384 278.751 276.515 269.306 271.363C283.902 257.625 293.347 237.018 293.347 215.553C293.347 172.621 259.002 138.276 216.071 138.276C173.139 138.276 138.794 172.621 138.794 215.553C138.794 237.877 148.239 257.625 162.836 272.222C137.936 285.96 118.187 308.284 109.601 335.761H61.5178Z"
        variants={iconInnerFrame}
         strokeWidth="5"
      />
      <motion.path
        variants={iconWhitePart}
         strokeWidth="5"
        d="M173.139 215.553C173.139 191.512 192.029 172.622 216.071 172.622C240.112 172.622 259.002 191.512 259.002 215.553C259.002 239.595 240.112 258.485 216.071 258.485C192.029 258.485 173.139 239.595 173.139 215.553Z"
      />
      <motion.path
        variants={iconWhitePart}
         strokeWidth="5"
        d="M146.522 335.76C159.401 310.002 185.16 292.829 216.071 292.829C236.678 292.829 256.426 300.557 271.023 315.153C277.033 321.164 282.185 328.033 285.619 335.76H146.522Z"
      />
      <motion.path
        variants={iconWhitePart}
         strokeWidth="5"
        d="M404.969 352.934C395.524 352.934 387.796 360.661 387.796 370.106C387.796 379.551 395.524 387.279 404.969 387.279C414.414 387.279 422.142 379.551 422.142 370.106C422.142 366.672 421.283 364.096 419.566 361.52C416.99 356.368 410.979 352.934 404.969 352.934Z"
      />
      <motion.path
        variants={iconWhitePart}
         strokeWidth="5"
        d="M121.622 121.104C93.287 121.104 70.104 144.286 70.104 172.621V189.794C70.104 199.239 77.8317 206.966 87.2766 206.966C96.7215 206.966 104.449 199.239 104.449 189.794V172.621C104.449 163.176 112.177 155.449 121.622 155.449C131.067 155.449 138.794 147.721 138.794 138.276C138.794 128.831 131.067 121.104 121.622 121.104Z"
      />
    </motion.svg>
  );
}
