import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function MarketingCloud({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconOuterFrame = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#CE6200",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#CE6200",
      },
    },
  };
  const iconInnerFrame = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#FCAC63",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#FCAC63",
      },
    },
  };
  const iconPerson = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#fff",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#fff",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        d="M365.769 323.846L467.308 425.384L421.154 471.538L319.615 370"
        variants={iconPerson}
         strokeWidth="5"
      />
      <motion.path
        d="M421.154 490C416.265 489.979 411.585 488.021 408.138 484.554L306.6 383.015C303.575 379.484 301.995 374.941 302.175 370.294C302.354 365.648 304.28 361.241 307.568 357.953C310.856 354.665 315.263 352.739 319.91 352.559C324.556 352.38 329.099 353.96 332.631 356.985L421.154 445.415L441.185 425.385L352.754 336.862C349.729 333.33 348.149 328.787 348.328 324.141C348.508 319.494 350.434 315.087 353.722 311.799C357.01 308.511 361.417 306.585 366.064 306.405C370.71 306.226 375.253 307.806 378.785 310.831L480.323 412.369C483.761 415.828 485.691 420.507 485.691 425.385C485.691 430.262 483.761 434.941 480.323 438.4L434.169 484.554C430.723 488.021 426.042 489.979 421.154 490Z"
        variants={iconOuterFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M218.077 397.693C320.037 397.693 402.692 315.038 402.692 213.077C402.692 111.117 320.037 28.4619 218.077 28.4619C116.117 28.4619 33.4617 111.117 33.4617 213.077C33.4617 315.038 116.117 397.693 218.077 397.693Z"
        variants={iconInnerFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M218.077 416.154C177.912 416.154 138.649 404.244 105.253 381.929C71.8577 359.615 45.8288 327.899 30.4584 290.791C15.088 253.684 11.0664 212.852 18.9022 173.459C26.7379 134.066 46.0791 97.8807 74.4799 69.4799C102.881 41.0791 139.066 21.7379 178.459 13.9022C217.852 6.06639 258.684 10.088 295.791 25.4584C332.899 40.8288 364.615 66.8577 386.929 100.253C409.244 133.649 421.154 172.912 421.154 213.077C421.154 266.936 399.758 318.59 361.674 356.674C323.59 394.758 271.936 416.154 218.077 416.154ZM218.077 46.9232C185.215 46.9232 153.091 56.6679 125.767 74.9251C98.443 93.1823 77.1467 119.132 64.5709 149.493C51.9951 179.853 48.7047 213.261 55.1158 245.492C61.5268 277.723 77.3515 307.328 100.588 330.565C123.826 353.802 153.431 369.627 185.662 376.038C217.893 382.449 251.301 379.159 281.661 366.583C312.022 354.007 337.972 332.711 356.229 305.387C374.486 278.063 384.231 245.939 384.231 213.077C384.231 169.01 366.725 126.748 335.565 95.5885C304.406 64.4286 262.144 46.9232 218.077 46.9232Z"
        variants={iconOuterFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M218.077 250C191.147 250 165.321 260.698 146.278 279.74C127.236 298.782 116.539 324.609 116.539 351.538V367.231C146.682 387.082 181.984 397.662 218.077 397.662C254.17 397.662 289.472 387.082 319.615 367.231V351.538C319.615 324.609 308.918 298.782 289.876 279.74C270.833 260.698 245.007 250 218.077 250Z"
        variants={iconPerson}
         strokeWidth="5"
      />
      <motion.path
        d="M218.077 250C258.861 250 291.923 216.938 291.923 176.154C291.923 135.37 258.861 102.308 218.077 102.308C177.293 102.308 144.231 135.37 144.231 176.154C144.231 216.938 177.293 250 218.077 250Z"
        variants={iconPerson}
         strokeWidth="5"
      />
      <motion.path
        d="M218.077 268.461C199.82 268.461 181.973 263.047 166.793 252.904C151.614 242.762 139.782 228.345 132.796 211.478C125.809 194.611 123.981 176.051 127.543 158.145C131.105 140.239 139.896 123.792 152.806 110.882C165.715 97.9726 182.163 89.1811 200.069 85.6194C217.974 82.0577 236.534 83.8857 253.402 90.8722C270.269 97.8588 284.685 109.69 294.828 124.87C304.971 140.05 310.385 157.897 310.385 176.153C310.385 188.275 307.997 200.279 303.358 211.478C298.719 222.677 291.92 232.853 283.348 241.425C274.777 249.996 264.601 256.796 253.402 261.435C242.202 266.074 230.199 268.461 218.077 268.461ZM218.077 120.769C207.123 120.769 196.415 124.017 187.307 130.103C178.199 136.189 171.1 144.838 166.908 154.959C162.716 165.079 161.619 176.215 163.756 186.958C165.894 197.702 171.168 207.571 178.914 215.316C186.66 223.062 196.528 228.337 207.272 230.474C218.015 232.611 229.151 231.514 239.272 227.322C249.392 223.13 258.042 216.031 264.128 206.923C270.213 197.816 273.461 187.107 273.461 176.153C273.461 161.465 267.626 147.377 257.24 136.991C246.853 126.604 232.766 120.769 218.077 120.769Z"
        variants={iconOuterFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M319.615 370C314.719 370 310.023 368.055 306.561 364.592C303.099 361.13 301.154 356.434 301.154 351.538C301.154 329.505 292.401 308.374 276.821 292.794C261.241 277.214 240.11 268.461 218.077 268.461C196.044 268.461 174.913 277.214 159.333 292.794C143.753 308.374 135 329.505 135 351.538C135 356.434 133.055 361.13 129.593 364.592C126.131 368.055 121.435 370 116.539 370C111.642 370 106.946 368.055 103.484 364.592C100.022 361.13 98.077 356.434 98.077 351.538C98.077 319.712 110.72 289.19 133.224 266.685C155.729 244.181 186.251 231.538 218.077 231.538C249.903 231.538 280.425 244.181 302.93 266.685C325.434 289.19 338.077 319.712 338.077 351.538C338.077 356.434 336.132 361.13 332.67 364.592C329.208 368.055 324.512 370 319.615 370Z"
        variants={iconOuterFrame}
         strokeWidth="5"
      />
      <motion.path
        d="M218.077 416.154C177.912 416.154 138.649 404.244 105.253 381.929C71.8577 359.615 45.8288 327.899 30.4584 290.791C15.088 253.684 11.0664 212.852 18.9022 173.459C26.7379 134.066 46.0791 97.8807 74.4799 69.4799C102.881 41.0791 139.066 21.7379 178.459 13.9022C217.852 6.06639 258.684 10.088 295.791 25.4584C332.899 40.8288 364.615 66.8577 386.929 100.253C409.244 133.649 421.154 172.912 421.154 213.077C421.154 266.936 399.758 318.59 361.674 356.674C323.59 394.758 271.936 416.154 218.077 416.154ZM218.077 46.9232C185.215 46.9232 153.091 56.6679 125.767 74.9251C98.443 93.1823 77.1467 119.132 64.5709 149.493C51.9951 179.853 48.7047 213.261 55.1158 245.492C61.5268 277.723 77.3515 307.328 100.588 330.565C123.826 353.802 153.431 369.627 185.662 376.038C217.893 382.449 251.301 379.159 281.661 366.583C312.022 354.007 337.972 332.711 356.229 305.387C374.486 278.063 384.231 245.939 384.231 213.077C384.231 169.01 366.725 126.748 335.565 95.5885C304.406 64.4286 262.144 46.9232 218.077 46.9232Z"
        variants={iconOuterFrame}
         strokeWidth="5"
      />
    </motion.svg>
  );
}
