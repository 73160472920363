import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Platform({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconFrame = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#211746",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#7673C0",
      },
    },
  };
  const iconWhitePart = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#fff",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        d="M169.923 20H364.538L293.769 179.231H391.077L143.385 480L196.462 250H108L169.923 20Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={iconFrame}
        strokeWidth="29"
      />
      <motion.path
        d="M196.461 179.231L223 81.9229"
        variants={iconWhitePart}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="19"
      />
    </motion.svg>
  );
}
