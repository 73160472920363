import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function SalesforceCloud({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconCloud = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#00A1E0",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#00A1E0",
      },
    },
  };
  const iconText = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      stroke: "#fff",
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "#fff",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconCloud}
        strokeWidth="5"
        d="M209.747 118.643C225.227 102.513 246.779 92.5094 270.615 92.5094C302.301 92.5094 329.945 110.178 344.667 136.407C357.46 130.691 371.621 127.511 386.52 127.511C443.668 127.511 490 174.246 490 231.894C490 289.549 443.668 336.284 386.52 336.284C379.545 336.284 372.728 335.586 366.137 334.253C353.173 357.378 328.468 373.002 300.113 373.002C288.243 373.002 277.015 370.26 267.019 365.385C253.877 396.297 223.258 417.973 187.573 417.973C150.41 417.973 118.738 394.458 106.581 361.48C101.268 362.609 95.7641 363.197 90.1162 363.197C45.8701 363.197 10 326.957 10 282.246C10 252.284 26.1162 226.123 50.0615 212.126C45.1316 200.783 42.3897 188.263 42.3897 175.101C42.3897 123.682 84.1333 82 135.621 82C165.85 82 192.715 96.3726 209.747 118.643Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M79.5233 256.228C79.2224 257.014 79.6327 257.178 79.7284 257.315C80.631 257.972 81.5472 258.443 82.4703 258.97C87.366 261.568 91.9882 262.327 96.8224 262.327C106.669 262.327 112.781 257.09 112.781 248.659V248.495C112.781 240.7 105.882 237.869 99.407 235.825L98.566 235.551C93.684 233.965 89.472 232.597 89.472 229.384V229.213C89.472 226.464 91.9335 224.44 95.7489 224.44C99.9882 224.44 105.021 225.849 108.262 227.64C108.262 227.64 109.212 228.255 109.561 227.332C109.752 226.84 111.393 222.423 111.564 221.944C111.749 221.425 111.421 221.042 111.086 220.837C107.387 218.587 102.272 217.049 96.9797 217.049L95.9951 217.055C86.9831 217.055 80.6925 222.498 80.6925 230.3V230.464C80.6925 238.69 87.6327 241.356 94.1352 243.216L95.1814 243.537C99.9199 244.994 104.002 246.245 104.002 249.582V249.746C104.002 252.796 101.349 255.066 97.0686 255.066C95.407 255.066 90.1079 255.031 84.3848 251.414C83.6942 251.011 83.2908 250.717 82.7575 250.396C82.4771 250.218 81.7728 249.91 81.4652 250.84L79.5233 256.228Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M223.667 256.228C223.366 257.014 223.776 257.178 223.872 257.315C224.775 257.972 225.691 258.443 226.614 258.97C231.51 261.568 236.132 262.327 240.966 262.327C250.812 262.327 256.925 257.09 256.925 248.659V248.495C256.925 240.7 250.026 237.869 243.551 235.825L242.71 235.551C237.828 233.965 233.616 232.597 233.616 229.384V229.213C233.616 226.464 236.077 224.44 239.893 224.44C244.132 224.44 249.165 225.849 252.406 227.64C252.406 227.64 253.356 228.255 253.705 227.332C253.896 226.84 255.537 222.423 255.708 221.944C255.893 221.425 255.565 221.042 255.229 220.837C251.53 218.587 246.416 217.049 241.123 217.049L240.139 217.055C231.127 217.055 224.836 222.498 224.836 230.3V230.464C224.836 238.69 231.776 241.356 238.279 243.216L239.325 243.537C244.064 244.994 248.153 246.245 248.153 249.582V249.746C248.153 252.796 245.493 255.066 241.212 255.066C239.551 255.066 234.252 255.031 228.529 251.414C227.838 251.011 227.428 250.731 226.908 250.396C226.73 250.279 225.896 249.958 225.609 250.84L223.667 256.228Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M322.07 239.713C322.07 244.479 321.181 248.233 319.431 250.886C317.701 253.512 315.082 254.79 311.431 254.79C307.773 254.79 305.167 253.518 303.465 250.886C301.742 248.24 300.867 244.479 300.867 239.713C300.867 234.954 301.742 231.207 303.465 228.582C305.167 225.983 307.773 224.718 311.431 224.718C315.082 224.718 317.701 225.983 319.438 228.582C321.181 231.207 322.07 234.954 322.07 239.713ZM330.289 230.879C329.482 228.151 328.224 225.744 326.549 223.741C324.873 221.73 322.754 220.117 320.238 218.941C317.728 217.771 314.761 217.177 311.431 217.177C308.094 217.177 305.126 217.771 302.617 218.941C300.101 220.117 297.981 221.73 296.299 223.741C294.631 225.751 293.373 228.158 292.559 230.879C291.759 233.594 291.355 236.561 291.355 239.713C291.355 242.865 291.759 245.84 292.559 248.547C293.373 251.269 294.624 253.676 296.306 255.686C297.981 257.696 300.108 259.303 302.617 260.445C305.133 261.587 308.094 262.168 311.431 262.168C314.761 262.168 317.721 261.587 320.238 260.445C322.747 259.303 324.873 257.696 326.549 255.686C328.224 253.682 329.482 251.276 330.289 248.547C331.096 245.833 331.499 242.859 331.499 239.713C331.499 236.568 331.096 233.594 330.289 230.879Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M397.78 253.517C397.507 252.717 396.734 253.018 396.734 253.018C395.538 253.476 394.266 253.9 392.912 254.112C391.538 254.324 390.027 254.434 388.406 254.434C384.427 254.434 381.268 253.251 379.004 250.912C376.734 248.574 375.462 244.793 375.476 239.678C375.49 235.022 376.611 231.521 378.628 228.854C380.632 226.201 383.681 224.841 387.75 224.841C391.141 224.841 393.726 225.23 396.433 226.085C396.433 226.085 397.083 226.365 397.391 225.517C398.109 223.521 398.642 222.092 399.408 219.897C399.627 219.275 399.093 219.008 398.902 218.933C397.835 218.516 395.319 217.839 393.418 217.552C391.64 217.278 389.562 217.135 387.251 217.135C383.798 217.135 380.721 217.723 378.088 218.899C375.462 220.068 373.233 221.682 371.469 223.692C369.705 225.702 368.365 228.109 367.469 230.83C366.58 233.545 366.129 236.526 366.129 239.678C366.129 246.495 367.968 252.006 371.599 256.041C375.237 260.088 380.7 262.146 387.825 262.146C392.037 262.146 396.358 261.292 399.463 260.068C399.463 260.068 400.057 259.781 399.798 259.09L397.78 253.517Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M412.161 235.148C412.55 232.501 413.282 230.3 414.41 228.584C416.113 225.978 418.711 224.549 422.362 224.549C426.014 224.549 428.427 225.985 430.157 228.584C431.306 230.3 431.805 232.597 432.003 235.148H412.161ZM439.832 229.329C439.135 226.696 437.405 224.037 436.27 222.819C434.479 220.891 432.728 219.544 430.991 218.792C428.721 217.821 426 217.178 423.019 217.178C419.545 217.178 416.393 217.76 413.836 218.963C411.272 220.166 409.118 221.807 407.429 223.852C405.74 225.89 404.468 228.317 403.661 231.072C402.848 233.814 402.438 236.802 402.438 239.954C402.438 243.161 402.861 246.149 403.702 248.837C404.55 251.544 405.904 253.931 407.737 255.907C409.562 257.896 411.914 259.455 414.732 260.543C417.528 261.623 420.926 262.184 424.831 262.177C432.865 262.149 437.097 260.358 438.841 259.394C439.149 259.223 439.443 258.922 439.073 258.06L437.255 252.966C436.981 252.207 436.208 252.488 436.208 252.488C434.219 253.226 431.388 254.553 424.79 254.539C420.475 254.532 417.275 253.26 415.272 251.271C413.214 249.233 412.208 246.238 412.031 242.013L439.853 242.04C439.853 242.04 440.584 242.026 440.66 241.315C440.687 241.014 441.617 235.599 439.832 229.329Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M189.345 235.148C189.741 232.501 190.466 230.3 191.594 228.584C193.297 225.978 195.895 224.549 199.547 224.549C203.198 224.549 205.612 225.985 207.348 228.584C208.49 230.3 208.989 232.597 209.188 235.148H189.345ZM217.01 229.329C216.312 226.696 214.589 224.037 213.454 222.819C211.663 220.891 209.912 219.544 208.176 218.792C205.906 217.821 203.184 217.178 200.203 217.178C196.736 217.178 193.577 217.76 191.02 218.963C188.456 220.166 186.302 221.807 184.613 223.852C182.924 225.89 181.653 228.317 180.846 231.072C180.039 233.814 179.622 236.802 179.622 239.954C179.622 243.161 180.046 246.149 180.887 248.837C181.735 251.544 183.088 253.931 184.921 255.907C186.747 257.896 189.099 259.455 191.916 260.543C194.712 261.623 198.111 262.184 202.015 262.177C210.049 262.149 214.282 260.358 216.025 259.394C216.333 259.223 216.627 258.922 216.258 258.06L214.446 252.966C214.165 252.207 213.393 252.488 213.393 252.488C211.403 253.226 208.579 254.553 201.967 254.539C197.659 254.532 194.459 253.26 192.456 251.271C190.398 249.233 189.393 246.238 189.215 242.013L217.037 242.04C217.037 242.04 217.769 242.026 217.844 241.315C217.871 241.014 218.801 235.599 217.01 229.329Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M129.208 253.365C128.12 252.497 127.97 252.278 127.601 251.717C127.054 250.862 126.773 249.645 126.773 248.1C126.773 245.652 127.58 243.895 129.255 242.712C129.235 242.719 131.649 240.627 137.324 240.702C141.31 240.756 144.873 241.345 144.873 241.345V253.994H144.879C144.879 253.994 141.344 254.753 137.365 254.992C131.703 255.334 129.187 253.358 129.208 253.365ZM140.278 233.816C139.15 233.734 137.686 233.686 135.936 233.686C133.55 233.686 131.245 233.987 129.085 234.568C126.91 235.15 124.955 236.059 123.273 237.262C121.584 238.473 120.223 240.018 119.238 241.851C118.254 243.683 117.755 245.844 117.755 248.264C117.755 250.726 118.179 252.866 119.026 254.616C119.874 256.374 121.098 257.837 122.657 258.965C124.202 260.093 126.11 260.921 128.326 261.42C130.507 261.919 132.982 262.172 135.69 262.172C138.541 262.172 141.385 261.939 144.141 261.468C146.869 261.003 150.22 260.326 151.15 260.114C152.073 259.895 153.098 259.615 153.098 259.615C153.789 259.444 153.734 258.705 153.734 258.705L153.72 233.262C153.72 227.683 152.23 223.546 149.297 220.982C146.377 218.425 142.076 217.133 136.517 217.133C134.432 217.133 131.074 217.42 129.064 217.823C129.064 217.823 122.985 218.999 120.483 220.955C120.483 220.955 119.936 221.297 120.237 222.062L122.206 227.355C122.452 228.039 123.115 227.806 123.115 227.806C123.115 227.806 123.327 227.724 123.573 227.58C128.927 224.668 135.697 224.756 135.697 224.756C138.705 224.756 141.016 225.358 142.575 226.555C144.093 227.717 144.866 229.474 144.866 233.18V234.356C142.473 234.015 140.278 233.816 140.278 233.816Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M364.676 219.481C364.888 218.852 364.443 218.551 364.259 218.483C363.787 218.298 361.421 217.799 359.595 217.683C356.101 217.471 354.159 218.059 352.423 218.838C350.7 219.618 348.785 220.876 347.718 222.305V218.92C347.718 218.449 347.383 218.072 346.918 218.072H339.787C339.322 218.072 338.987 218.449 338.987 218.92V260.418C338.987 260.883 339.37 261.266 339.835 261.266H347.144C347.609 261.266 347.985 260.883 347.985 260.418V239.686C347.985 236.903 348.293 234.127 348.908 232.384C349.51 230.661 350.33 229.279 351.342 228.288C352.361 227.303 353.517 226.613 354.782 226.223C356.074 225.826 357.503 225.696 358.515 225.696C359.971 225.696 361.571 226.072 361.571 226.072C362.105 226.134 362.406 225.806 362.583 225.32C363.062 224.049 364.416 220.24 364.676 219.481Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M296.065 200.249C295.177 199.975 294.37 199.791 293.317 199.592C292.25 199.401 290.978 199.305 289.536 199.305C284.503 199.305 280.537 200.727 277.754 203.531C274.985 206.321 273.105 210.567 272.161 216.153L271.819 218.033H265.501C265.501 218.033 264.736 218.006 264.571 218.84L263.539 224.632C263.464 225.179 263.703 225.527 264.442 225.527H270.589L264.353 260.345C263.867 263.148 263.307 265.452 262.684 267.203C262.076 268.926 261.481 270.218 260.742 271.162C260.031 272.064 259.361 272.734 258.199 273.124C257.242 273.445 256.134 273.596 254.924 273.596C254.254 273.596 253.358 273.486 252.695 273.35C252.038 273.22 251.689 273.076 251.19 272.864C251.19 272.864 250.472 272.591 250.185 273.309C249.96 273.903 248.318 278.409 248.12 278.963C247.929 279.517 248.202 279.948 248.551 280.078C249.371 280.365 249.98 280.556 251.095 280.823C252.64 281.186 253.946 281.206 255.17 281.206C257.727 281.206 260.065 280.844 262.001 280.146C263.942 279.442 265.638 278.218 267.142 276.563C268.763 274.772 269.782 272.898 270.753 270.334C271.717 267.804 272.544 264.659 273.201 260.994L279.471 225.527H288.633C288.633 225.527 289.406 225.555 289.563 224.714L290.602 218.929C290.671 218.375 290.438 218.033 289.693 218.033H280.797C280.845 217.835 281.248 214.703 282.267 211.756C282.705 210.505 283.525 209.486 284.216 208.789C284.9 208.105 285.686 207.62 286.548 207.339C287.43 207.052 288.435 206.915 289.536 206.915C290.37 206.915 291.197 207.011 291.819 207.141C292.681 207.326 293.016 207.421 293.242 207.49C294.151 207.763 294.274 207.497 294.452 207.059L296.578 201.22C296.797 200.591 296.257 200.324 296.065 200.249Z"
      />
      <motion.path
        variants={iconText}
        strokeWidth="2"
        d="M171.775 260.42C171.775 260.885 171.44 261.261 170.975 261.261H163.597C163.132 261.261 162.804 260.885 162.804 260.42V201.042C162.804 200.577 163.132 200.201 163.597 200.201H170.975C171.44 200.201 171.775 200.577 171.775 201.042V260.42Z"
      />
    </motion.svg>
  );
}
